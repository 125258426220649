export class AssetsFoldingIterator {
    constructor(assetsFolding, maxLimit, totalCount, maxDisplayed, foldingDisabled, onThresholdReach) {
        this.onThresholdReach = () => { };
        this.visibleAssetsCount = () => this.visibleAssetOrders.length;
        this.firstVisibleAssetOrder = () => this.visibleAssetOrders[0];
        this.lastVisibleAssetOrder = () => this.visibleAssetOrders[this.visibleAssetOrders.length - 1];
        this.maxDisplayedExceeded = () => this.visibleAssetsCount() > this.maxDisplayed;
        this.hasMore = (fetchDirection) => (fetchDirection == 'forward' && this.hasNext()) || (fetchDirection == 'backward' && this.hasPrevious());
        this.more = (fetchDirection) => fetchDirection == 'forward' ? this.next() : this.previous();
        this.hasNext = () => this.lastVisibleAssetOrder() < this.totalCount;
        this.next = (orderToFind, includeFolded) => {
            const groups = (this.foldingDisabled || includeFolded === true) ? this.allAssetsFolding : this.onlyNotFoldedAssetsFolding;
            const order = orderToFind || this.lastVisibleAssetOrder() + 1 || 1;
            const matchingGroup = groups.find(g => order <= g.end && order > g.start);
            const group = matchingGroup || groups.find(g => order - 1 <= g.start);
            const alreadyLoadedOrder = this.visibleAssetOrders.find(o => o > order) || this.totalCount;
            const limit = (matchingGroup)
                ? Math.min(Math.min(matchingGroup.end, alreadyLoadedOrder) - order + 1, this.maxLimit)
                : (group)
                    ? Math.min(Math.min(group.end, alreadyLoadedOrder) - group.start, this.maxLimit)
                    : undefined;
            const offset = (matchingGroup) ? order - 1 : group === null || group === void 0 ? void 0 : group.start;
            return { offset, fetchDirection: 'forward', limit, onDataFetched: undefined, fetchType: 'scroll' };
        };
        this.hasPrevious = () => this.firstVisibleAssetOrder() > 1;
        this.previous = () => {
            const groups = (this.foldingDisabled) ? this.allAssetsFolding : this.onlyNotFoldedAssetsFolding;
            const order = this.firstVisibleAssetOrder() - 1;
            const matchingGroup = groups.find(g => order < g.end && order > g.start);
            const group = matchingGroup || groups[groups.findIndex((af) => af.start >= order) - 1];
            const offset = matchingGroup ? Math.max(group === null || group === void 0 ? void 0 : group.start, order - this.maxLimit) : Math.max(group === null || group === void 0 ? void 0 : group.start, (group === null || group === void 0 ? void 0 : group.end) - this.maxLimit);
            const limit = matchingGroup
                ? Math.min(order - offset, this.maxLimit)
                : group
                    ? Math.min(group.end - group.start, this.maxLimit)
                    : undefined;
            return { offset, fetchDirection: 'backward', limit, onDataFetched: undefined, fetchType: 'scroll' };
        };
        this.addVisibleAssetOrders = (orders) => {
            this.visibleAssetOrders = [...this.visibleAssetOrders, ...orders].sort((a1, a2) => a1 - a2);
        };
        this.clear = () => {
            this.visibleAssetOrders = [];
        };
        this.inVisibleRange = (order) => order > this.visibleAssetOrders[0] && order < this.visibleAssetOrders[this.visibleAssetOrders.length - 1];
        this.allAssetsFolding = assetsFolding;
        this.onlyNotFoldedAssetsFolding = assetsFolding.filter(af => af.status == 'NOT_FOLDED');
        this.maxLimit = maxLimit;
        this.totalCount = totalCount;
        this.visibleAssetOrders = [];
        this.maxDisplayed = maxDisplayed;
        this.foldingDisabled = foldingDisabled;
        this.onThresholdReach = onThresholdReach;
    }
    assetsLoaded(fetchDirection, loadedAssets) {
        this.addVisibleAssetOrders(loadedAssets.map(a => a.order));
        if (fetchDirection == 'forward') {
            if (this.maxDisplayedExceeded()) {
                this.visibleAssetOrders = this.visibleAssetOrders.slice(loadedAssets.length);
                this.onThresholdReach(0, this.firstVisibleAssetOrder());
            }
        }
        else if (fetchDirection == 'backward') {
            if (this.maxDisplayedExceeded()) {
                this.visibleAssetOrders = this.visibleAssetOrders.slice(0, -loadedAssets.length);
                this.onThresholdReach(this.lastVisibleAssetOrder(), this.totalCount);
            }
        }
    }
}
