export const TOOLTIP_ID = "baseEditorTooltip";
export class EditorTooltip extends HTMLElement {
    constructor() {
        super();
        this.contnet = document.createElement("span");
        this.keyboardShortcut = document.createElement("div");
        this.id = TOOLTIP_ID;
    }
    connectedCallback() {
        this.appendChild(this.contnet);
        this.appendChild(this.keyboardShortcut);
    }
    showTooltip(refElement, text, keyboardShortcut = [], tooltipPosition) {
        this.contnet.innerText = text;
        this.setTooltipPosition(refElement, tooltipPosition);
        while (this.keyboardShortcut.firstChild) {
            this.keyboardShortcut.removeChild(this.keyboardShortcut.firstChild);
        }
        keyboardShortcut.forEach((key) => {
            const entry = document.createElement("span");
            entry.className = "keyboardShortcut";
            entry.innerText = key;
            this.keyboardShortcut.appendChild(entry);
        });
        this.classList.add("visible");
        refElement.addEventListener("mouseleave", () => {
            this.hideTooltip();
        });
    }
    hideTooltip() {
        this.classList.remove("visible");
    }
    setTooltipPosition(el, tooltipPosition) {
        const [rect] = Array.from(el.getClientRects());
        if (!rect)
            return;
        this.classList.add("visible");
        const extraSpace = this.getNavbarWidth();
        const margin = 4;
        if (tooltipPosition == 'left') {
            this.style.left = rect.left < 200
                ? `0px`
                : `${rect.left - extraSpace - this.offsetWidth - margin}px`;
            this.style.top = `${rect.top + rect.height / 2 - this.offsetHeight / 2}px`;
        }
        else if (tooltipPosition == 'right') {
            this.style.left = rect.left < 200
                ? `0px`
                : `${rect.right - extraSpace + margin}px`;
            this.style.top = `${rect.top + rect.height / 2 - this.offsetHeight / 2}px`;
        }
        else {
            this.style.left = rect.left < 200
                ? `0px`
                : `${rect.left - extraSpace - ((this.offsetWidth - rect.width) / 2)}px`;
            this.style.top = `${rect.top + el.offsetHeight + margin}px`;
        }
    }
    getNavbarWidth() {
        var _a;
        return ((_a = document.getElementById("app-navbar")) === null || _a === void 0 ? void 0 : _a.clientWidth) || 0;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-tooltip", EditorTooltip));
