import EditorModule from "../EditorModule";
import { getProgress, getJobSectionProgress, reportWorkSeconds } from "api-operations/editor/editor";
import { removeCaretGuards } from "editorNextGen/AssetElement";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { restoreButton as restoreButtonIcon } from "editorNextGen/icons/restore";
import { calculateProgress, consumeEvent, sameAsset } from "editorNextGen/utils";
import { secondsToReadableUnits } from "helpers/utils";
import "./style.scss";
import UserService from "UserService";
const LOG_TIME_INTERVAL = 30;
export default class ContentEditionModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "ContentEditionModule";
        this.assignmentProgressBar = document.createElement("progress");
        this.sectionProgressBar = document.createElement("progress");
        this.workTimeInterval = null;
        this.duplicateUpdateTimeouts = new Map();
        this._assetEdition = true;
        this.focused = true;
        this.isCompletable = (asset) => this.editor.modules.every(m => m.assetCompletable(asset));
        this.getRestoreButton = (asset) => asset.querySelector("button.restore");
        this.getDoneButton = (asset) => asset.querySelector("button.done");
        const { statusBar, sectionHeader, sectionBar } = editor;
        this.statusBarAssignmentAssetsRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.remainingAssets')}: `);
        this.statusBarAssignmentTimeRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.estTimeRemaining')}: `);
        this.statusBarSectionAssetsTotal = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.totalAssetsSection')}: `, true);
        this.statusBarSectionAssetsRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.remainingAssetsSection')}: `, true);
        this.assignmentProgressBar.max = 1;
        this.sectionProgressBar.max = 1;
        this.editor.header.appendChild(this.assignmentProgressBar);
        document.querySelector('xfl-new-editor-section-header')
            ? sectionHeader.appendChild(this.sectionProgressBar)
            : sectionBar.appendChild(this.sectionProgressBar);
        window.addEventListener('blur', () => { this.focused = false; });
        window.addEventListener('focus', () => { this.focused = true; });
    }
    unload() {
        this.editor.content.contentEditable = "false";
        this.clearWorkTimeInterval();
        Array.from(this.duplicateUpdateTimeouts.values()).forEach(window.clearInterval);
        this.disableAssetsEdition();
        this.assignmentProgressBar.remove();
        this.sectionProgressBar.remove();
        this.editor.modules = this.editor.modules.filter(m => m != this);
    }
    disableAssetsEdition() {
        this.editor.content.querySelectorAll("xfl-new-editor-asset").forEach((a) => {
            var _a, _b;
            a.contentDiv.contentEditable = "false";
            (_a = a.querySelector("button.edit")) === null || _a === void 0 ? void 0 : _a.remove();
            (_b = this.getDoneButton(a)) === null || _b === void 0 ? void 0 : _b.remove();
        });
    }
    finishLoading() {
        var _a;
        this.setWorkTimeInterval();
        this.updateProgress(undefined, [(_a = this.editor.section) === null || _a === void 0 ? void 0 : _a.id]);
        this.editor.content.contentEditable = "true";
        this.editor.placeCursorInActiveAsset();
    }
    clearWorkTimeInterval() {
        this.workTimeInterval && window.clearInterval(this.workTimeInterval);
    }
    setWorkTimeInterval() {
        this.workTimeInterval = window.setInterval(this.reportWorkTime.bind(this), LOG_TIME_INTERVAL * 1000);
    }
    input() {
        const { activeAsset } = this.editor;
        if (!activeAsset) {
            return;
        }
        this.contentChanged(activeAsset);
        this.updateDoneButton(activeAsset);
        this.updateRestoreButton(activeAsset);
        const { assetId } = activeAsset;
        if (this.duplicateUpdateTimeouts.has(assetId)) {
            clearTimeout(this.duplicateUpdateTimeouts.get(assetId));
        }
        const duplicates = this.editor.getAssetDuplicates(activeAsset);
        this.duplicateUpdateTimeouts.set(assetId, window.setTimeout(() => {
            duplicates.forEach((a) => { a.currentText = activeAsset.currentText; });
            this.duplicateUpdateTimeouts.delete(assetId);
        }, duplicates.length * 10)); // Timeout duration depends on how much copies this asset has
    }
    isRecycled(assetElement) {
        return !assetElement.paid && assetElement.locked;
    }
    assetCreated(assetElement, asset = undefined) {
        if (assetElement.translationLevel == "NO_TRANSLATION") {
            return;
        }
        if (this._assetEdition) {
            const recycledEditLabel = document.createElement("span");
            recycledEditLabel.textContent = i18next.t("editor.recycledHint");
            recycledEditLabel.className = "recycledHint";
            this.isRecycled(assetElement) && assetElement.iconBar.appendChild(recycledEditLabel);
            const editButton = document.createElement("button");
            editButton.innerHTML = i18next.t("editor.edit");
            editButton.className = "edit";
            editButton.addEventListener("click", assetElement.unlockClicked.bind(assetElement));
            assetElement.iconBar.appendChild(editButton);
            const restoreButton = document.createElement("button");
            restoreButton.innerHTML = restoreButtonIcon;
            restoreButton.className = "restore";
            restoreButton.addEventListener("click", assetElement.restoreClicked.bind(assetElement));
            restoreButton.addEventListener("mouseenter", (e) => {
                this.getSimpleTooltip().showTooltip(restoreButton, e, 'bottom');
            });
            restoreButton.addEventListener("mouseleave", (e) => {
                this.getSimpleTooltip().hide();
            });
            assetElement.iconBar.appendChild(restoreButton);
            const doneButton = document.createElement("button");
            doneButton.textContent = i18next.t("editor.complete");
            doneButton.className = "done";
            doneButton.addEventListener("click", assetElement.doneClicked.bind(assetElement));
            assetElement.iconBar.appendChild(doneButton);
        }
    }
    getSimpleTooltip() {
        const simpleTooltip = document.querySelector("xfl-new-popup-ele");
        simpleTooltip.content = i18next.t("editor.restore");
        return simpleTooltip;
    }
    assetActivationChanged(asset, active) {
        if (active) {
            this.editor.commitTimeouts.get(asset.assetId) && this.editor.commit(asset);
            this.updateDoneButton(asset);
            this.updateRestoreButton(asset);
        }
    }
    assetCompletionChanged(asset, completed, onCommitCompleted) {
        this.editor.commit(asset)
            .then(() => { this.updateProgress(undefined, asset.sections); })
            .then(() => { if (completed && onCommitCompleted)
            onCommitCompleted(); })
            .catch(error => this.editor.setState({ error }));
    }
    assetCompletabilityChanged(asset) {
        this.updateDoneButton(asset);
    }
    updateDoneButton(asset) {
        this.getDoneButton(asset).disabled = !this.isCompletable(asset);
    }
    assetInstanceLockChanged(asset, locked) {
        this.updateAssetContentEditable(asset);
    }
    assetInstanceCompletionChanged(asset, completed) {
        this.updateAssetContentEditable(asset);
        this.updateRestoreButton(asset);
    }
    updateRestoreButton(asset) {
        const restoreButton = this.getRestoreButton(asset);
        if (restoreButton) {
            restoreButton.style.display = (asset.modified && !asset.completed) ? "block" : "none";
        }
    }
    keyDownComplete(event) {
        const onCommitCompleted = () => {
            this.editor.jumpToNextUnfinishedAsset();
            this.editor.placeCursorInActiveAsset();
        };
        const { activeAsset } = this.editor;
        switch (event.key) {
            case "Enter":
                if ((event.ctrlKey || event.metaKey) && activeAsset) {
                    if (!activeAsset.paid && activeAsset.locked) {
                        activeAsset.locked = false;
                        this.editor.placeCursorInActiveAsset();
                    }
                    else {
                        if (this.editor.isTrustMiningStage && activeAsset.assetError && !activeAsset.completed) {
                            if (activeAsset.assetErrorType) {
                                activeAsset.setCompleted(!activeAsset.completed, onCommitCompleted);
                            }
                        }
                        else if (activeAsset.translationLevel != 'NO_TRANSLATION') {
                            activeAsset.setCompleted(!activeAsset.completed, onCommitCompleted);
                        }
                        if (activeAsset.translationLevel == 'NO_TRANSLATION') {
                            onCommitCompleted();
                        }
                    }
                    consumeEvent(event);
                }
                break;
        }
    }
    assetChanged(asset) {
        const offset = findOffsetInRoot(asset.contentDiv);
        this.editor.observer.disconnect();
        asset.currentText = asset.currentText;
        this.editor.observer.observe(asset.contentDiv, { subtree: true, characterData: true });
        const [x, y] = findOffsetInChildNodeAtTextIndex(asset.contentDiv, offset);
        setRange(y, x);
    }
    keyPress(event) {
        if (!this.isSelectionOverwritable()) {
            consumeEvent(event);
        }
        switch (event.key) {
            case "Enter":
                consumeEvent(event);
                break;
        }
    }
    keyUp(event) {
        const range = window.getSelection().getRangeAt(0);
        try { // Firefox problem workaround
            range.startContainer.parentNode;
        }
        catch (error) {
            switch (event.key) {
                case "ArrowDown":
                    this.editor.jumpBy(1);
                    this.editor.placeCursorInActiveAsset();
                    break;
                case "ArrowUp":
                    this.editor.jumpBy(-1);
                    this.editor.placeCursorInActiveAsset(true);
            }
        }
        this.editor.updateSelectionRange();
    }
    clipboardEvent(event) {
        var _a;
        if (!this.isSelectionOverwritable()) {
            consumeEvent(event);
            return;
        }
        const { editor } = this;
        if (!editor.activeAsset)
            return;
        if (event.type === "paste" || event.type === "copy" || event.type === "cut") {
            HTTPLogger.externalAssetModification({
                type: event.type,
                assignmentId: editor.assignmentId,
                stage: editor.stage,
                totalAssets: editor.statusBar.totalAssets,
                translationLevel: editor.activeAsset.translationLevel,
                assetLength: (_a = editor.activeAsset) === null || _a === void 0 ? void 0 : _a.currentPlainText.length,
                username: UserService.getTokenUserInfo().login,
                sourceLanguage: editor.sourceLanguage,
                targetLanguage: editor.targetLanguage
            });
        }
    }
    isSelectionOverwritable() {
        const selection = window.getSelection();
        return !selection
            || selection.rangeCount == 1
                && sameAsset(selection.getRangeAt(0).startContainer, selection.getRangeAt(0).endContainer);
    }
    updateAssetContentEditable(asset) {
        asset.contentDiv.contentEditable = ((asset.paid || !asset.locked) && !asset.completed).toString();
    }
    contentChanged(asset) {
        this.editor.clearCommitTimeout(asset);
        this.editor.commitTimeouts.set(asset.assetId, window.setTimeout(() => this.editor.commit(asset), 5000));
    }
    updateProgress(editorProgess = undefined, sections) {
        const { assignmentId } = this.editor;
        Promise.all(sections.filter(sectionId => sectionId).map((sectionId) => this.updateSectionProgress(assignmentId, sectionId))).then(() => {
            this.updateAssignmentProgress(editorProgess, assignmentId);
        });
    }
    async updateAssignmentProgress(editorProgess, assignmentId) {
        try {
            const progress = editorProgess || await getProgress(assignmentId);
            const assignmentProgress = calculateProgress(progress.total, progress.completed);
            this.remainingAssignmentAssets = progress.total - progress.completed;
            this.assignmentProgressBar.value = assignmentProgress;
            this.assignmentTimeEstimated = progress.secondsRemaining;
            this.editor.modules.forEach(m => m.progressChanged(assignmentProgress));
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to update progress', error);
        }
    }
    async updateSectionProgress(assignmentId, sectionId) {
        try {
            const progress = await getJobSectionProgress(assignmentId, sectionId);
            const assetProgress = calculateProgress(progress.total, progress.completed);
            this.remainingSectionAssets = progress.total - progress.completed;
            this.totalSectionAssets = progress.total;
            this.sectionProgressBar.value = assetProgress;
            this.editor.updateSectionProgress(sectionId, progress.total, progress.completed);
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to update progress', error);
        }
    }
    async reportWorkTime() {
        if (document.visibilityState == "hidden" || !this.focused) {
            return;
        }
        try {
            await reportWorkSeconds(this.editor.assignmentId, LOG_TIME_INTERVAL);
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to report work time', error);
        }
    }
    get remainingAssignmentAssets() {
        return parseInt(this.statusBarAssignmentAssetsRemaining.textContent || "");
    }
    set remainingAssignmentAssets(assets) {
        this.statusBarAssignmentAssetsRemaining.textContent = assets.toString();
    }
    get assignmentTimeEstimated() {
        return Number(this.statusBarAssignmentTimeRemaining.dataset.seconds);
    }
    set assignmentTimeEstimated(duration) {
        this.statusBarAssignmentTimeRemaining.dataset.seconds = `${duration}` || undefined;
        this.statusBarAssignmentTimeRemaining.textContent = secondsToReadableUnits(duration || 0);
    }
    get remainingSectionAssets() {
        return parseInt(this.statusBarSectionAssetsRemaining.textContent || "");
    }
    set remainingSectionAssets(assets) {
        this.statusBarSectionAssetsRemaining.textContent = assets.toString();
    }
    get totalSectionAssets() {
        return parseInt(this.statusBarSectionAssetsTotal.textContent || "");
    }
    set totalSectionAssets(assets) {
        this.statusBarSectionAssetsTotal.textContent = assets.toString();
    }
    set assetEdition(edition) {
        this._assetEdition = edition;
    }
}
function findOffsetInRoot(root) {
    const selection = window.getSelection();
    if (selection && (selection === null || selection === void 0 ? void 0 : selection.focusNode)) {
        const range = document.createRange();
        range.setStart(root, 0);
        range.setEnd(selection === null || selection === void 0 ? void 0 : selection.focusNode, selection === null || selection === void 0 ? void 0 : selection.focusOffset);
        return removeCaretGuards(range.toString()).length + 1; // +1 for carret guard at the begining of text
    }
    return 0;
}
function setRange(newStartNode, newRangeStartOffset) {
    const range = document.createRange();
    range.setStart(newStartNode, newRangeStartOffset);
    const selection = window.getSelection();
    selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
    selection === null || selection === void 0 ? void 0 : selection.addRange(range);
}
function findOffsetInChildNodeAtTextIndex(node, index) {
    var _a, _b, _c;
    if (index == 0)
        return [0, node];
    var sum = 0;
    const nodes = getAllNestedNodes(Array.from(node.childNodes))
        .flat(20)
        .filter((node) => node instanceof Text);
    for (var i = 0; i < nodes.length; i++) {
        const n = nodes[i];
        sum = sum + (((_a = n.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0);
        // textNode = n
        if (sum >= index) {
            const range = document.createRange();
            range.setStart(n, (((_b = n.textContent) === null || _b === void 0 ? void 0 : _b.length) || 0) - (sum - index));
            return [(((_c = n.textContent) === null || _c === void 0 ? void 0 : _c.length) || 0) - (sum - index), n];
        }
    }
    return [0, node];
}
function getAllNestedNodes(nodes) {
    return nodes
        .map(node => {
        return node.childNodes.length ? getAllNestedNodes(Array.from(node.childNodes)) : node;
    });
}
