import { arrowUpSearchSvg } from "editorNextGen/icons/arrowUpSearch";
import { arrowDownSearchSvg } from "editorNextGen/icons/arrowDownSearch";
import './FoldedAssetsLoader.scss';
import { IconNgBuilder } from "editorNextGen/icons";
import { ExfluencyLoader } from "../../../components/loader";
import { postMetricUnfolding } from "api-operations/metrics/metrics";
export default class FoldedAssetsLoader extends HTMLElement {
    constructor(startIndex, endIndex, scroll, assignmentId) {
        super();
        this.loader = document.createElement("div");
        this.loadMore = document.createElement("div");
        this.counter = document.createElement('div');
        this.limit = 5;
        this.showFirstDescription = () => `Show first ${Math.min(this.limit, this.left())} folded assets`;
        this.showLastDescription = () => `Show last ${Math.min(this.limit, this.left())} folded assets`;
        this.counterText = () => `${this.left()}`;
        this.contentEditable = 'false';
        this.start = startIndex;
        this.end = endIndex;
        this.order = startIndex;
        const divider = document.createElement('hr');
        this.assignmentId = assignmentId;
        const middleContainer = document.createElement('div');
        middleContainer.classList.add('middleContainer');
        this.counter.textContent = this.counterText();
        this.counter.classList.add('counter');
        this.loadMore.className = 'loadMoreContainer';
        this.showFirstIcon = new IconNgBuilder(arrowUpSearchSvg, middleContainer)
            .setTooltip(this.showFirstDescription(), undefined, 'left')
            .setOnClick(() => {
            const limit = Math.min(this.limit, this.left());
            const offset = this.start;
            postMetricUnfolding(limit, assignmentId);
            this.showLoader();
            scroll.load(offset, 'manual_first', limit, () => { this.setStartAndEnd(offset + limit, this.end); });
        })
            .build();
        middleContainer.appendChild(this.counter);
        this.showLastIcon = new IconNgBuilder(arrowDownSearchSvg, middleContainer)
            .setTooltip(this.showLastDescription(), undefined, 'right')
            .setOnClick(() => {
            const limit = Math.min(this.limit, this.left());
            postMetricUnfolding(limit, assignmentId);
            this.showLoader();
            scroll.load(this.end - limit, 'manual_last', limit, () => { this.setStartAndEnd(this.start, this.end - limit); });
        })
            .build();
        this.loadMore.appendChild(divider);
        this.loadMore.appendChild(middleContainer);
        this.loader.className = 'loader';
        this.appendChild(this.loader);
        this.appendChild(this.loadMore);
    }
    left() {
        return Math.max(this.end - this.start, 0);
    }
    setStartAndEnd(start, end) {
        this.hideLoader();
        this.start = start;
        this.order = start;
        this.end = end;
        this.counter.textContent = this.counterText();
        this.loadMore.classList.toggle('hidden', this.left() < 1);
        this.showFirstIcon.setTooltipText(this.showFirstDescription());
        this.showLastIcon.setTooltipText(this.showLastDescription());
    }
    showLoader() {
        this.loader.appendChild(new ExfluencyLoader(false, true));
        this.loader.classList.remove('hidden');
        this.loadMore.classList.add('hidden');
    }
    hideLoader() {
        this.loader.classList.add('hidden');
        this.loader.innerHTML = '';
    }
    set order(order) {
        this.setAttribute("order", order + "");
    }
    get order() {
        return parseInt(this.getAttribute("order") || "");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-folded-assets-loader", FoldedAssetsLoader));
