/* eslint-disable consistent-return */
import axios from "axios";
const ENDPOINT_PREFIX = "/api/admin/metrics";
async function postMetrics(metric) {
    try {
        const url = ENDPOINT_PREFIX;
        return await axios.post(url, metric);
    }
    catch (e) {
        console.log("Exception while posting metrics", e);
    }
}
export async function postFoldedAssetEdition(assetId, assignmentId) {
    await postMetrics({ name: "FOLDED_ASSET_EDITION", value: 1, properties: { "ASSET_ID": assetId, "ASSIGNMENT_ID": assignmentId } });
}
export async function postMetricUnfolding(assetsCount, assignmentId) {
    await postMetrics({ name: "ASSETS_UNFOLDED", value: assetsCount, properties: { "ASSIGNMENT_ID": assignmentId } });
}
