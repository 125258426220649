import * as rxjs from 'rxjs';
import { AssetsFoldingIterator } from './AssetsFoldingIterator';
const SCROLL_THROTTLE_TIME = 500;
const SCROLL_THRESHOLD_FORWARD_PERCENT = 0.8;
const SCROLL_THRESHOLD_BACKWARD_PERCENT = 0.05;
export class InfiniteScroll {
    /**
   * Constructor
   *
   * @param container Scroll container
   * @param totalCount Total number of items
   * @param lastPosition Position to start with
   * @param maxBatchSize Max size of one call
   * @param maxDisplayed Size of maximum displayed items
   * @param fetchData Fetch data funtion. Return promise
   * @param onThresholdReach Callback on threshold reach
   */
    constructor(container, totalCount, lastPosition, maxBatchSize, maxDisplayed, fetchData, onThresholdReach, assetsFolding, foldingDisabled) {
        this.cache = new Map();
        this.loadingBackward = false;
        this.loadingForward = false;
        this.fetchData = () => Promise.resolve([]);
        this.disabledScroll = false;
        this.loadManually = (props) => this.pageByManual$.next(props);
        this.checkLoading = (fetchDirection) => (fetchDirection == 'forward' && !this.loadingForward) || (fetchDirection == 'backward' && !this.loadingBackward);
        this.fetchData = fetchData;
        this.container = container;
        this.assetsFoldingIterator = new AssetsFoldingIterator(assetsFolding, maxBatchSize, totalCount, maxDisplayed, foldingDisabled, onThresholdReach);
        // pageByManual$ will emits its current value whenever it is subscribed to
        this.pageByManual$ = new rxjs.BehaviorSubject(this.assetsFoldingIterator.next(lastPosition));
    }
    subscribe(options) {
        this.itemResults$.subscribe(options);
    }
    loadNextWhenThresholdReached() {
        if (isForwardScrollThresholdReached(this.container)) {
            if (this.assetsFoldingIterator.hasNext()) {
                this.loadManually(this.assetsFoldingIterator.next());
            }
        }
    }
    loadPreviousWhenThresholdReached() {
        if (isBackwardScrollThresholdReached(this.container)) {
            if (this.assetsFoldingIterator.hasPrevious()) {
                this.loadManually(this.assetsFoldingIterator.previous());
            }
        }
    }
    load(offset, fetchType, limit, onDataFetched) {
        this.loadManually({ offset, fetchDirection: 'forward', fetchType: fetchType, limit, onDataFetched });
    }
    moveTo(order, clearPreviousAssets, fetchType) {
        const orderInVisibleRange = this.assetsFoldingIterator.inVisibleRange(order);
        if (!orderInVisibleRange) {
            clearPreviousAssets();
            this.assetsFoldingIterator.clear();
        }
        const includeFolded = fetchType == 'search';
        const props = this.assetsFoldingIterator.next(order, includeFolded);
        this.loadManually({ ...props, fetchType });
    }
    updateAssetsState(assetId, content, completed = false) {
        this.updateAllAssetIds(assetId, (asset) => {
            asset.currentText = content;
            asset.completed = completed;
        });
    }
    updateAssetError(assetId, assetError) {
        this.updateAllAssetIds(assetId, (asset) => {
            asset.error = assetError || undefined;
        });
    }
    updateAllAssetIds(assetId, update) {
        this.cache.forEach((value) => {
            value.forEach((asset) => {
                if (asset.id === assetId)
                    update(asset);
            });
        });
    }
    setLoading(fetchDirection, state) {
        fetchDirection == 'forward' ? this.loadingForward = state
            : this.loadingBackward = state;
    }
    pageByScroll$() {
        return rxjs.fromEvent(this.container, 'scroll').pipe(rxjs.filter(() => !this.disabledScroll), rxjs.filter(() => isScrollThresholdReached(this.container)), rxjs.map(() => getScrollFetchDirection(this.container)), rxjs.filter(fetchDirection => this.checkLoading(fetchDirection)), rxjs.filter(fetchDirection => this.assetsFoldingIterator.hasMore(fetchDirection)), rxjs.map(fetchDirection => this.assetsFoldingIterator.more(fetchDirection)), rxjs.throttleTime(SCROLL_THROTTLE_TIME, undefined, { leading: true, trailing: true }));
    }
    async getAssets(page) {
        const cacheKey = `${page.offset}_ ${page.limit}`;
        const assets = this.cache.get(cacheKey) || await this.fetchData(page.offset, page.limit);
        if (!this.cache.has(cacheKey))
            this.cache.set(cacheKey, assets);
        return { ...page, assets };
    }
    get itemResults$() {
        return rxjs.merge(this.pageByManual$, this.pageByScroll$()).pipe(rxjs.filter(({ fetchDirection }) => this.checkLoading(fetchDirection)), rxjs.tap(({ fetchDirection }) => this.setLoading(fetchDirection, true)), rxjs.mergeMap((page) => rxjs.from(this.getAssets(page))), rxjs.tap(({ fetchDirection }) => { this.setLoading(fetchDirection, false); }), rxjs.tap(({ fetchDirection, assets }) => { this.assetsFoldingIterator.assetsLoaded(fetchDirection, assets); }), rxjs.tap(({ onDataFetched }) => { onDataFetched && onDataFetched(); }));
    }
    set disabled(value) {
        this.disabledScroll = value;
    }
}
const isScrollThresholdReached = (container) => isThresholdReached(container.scrollHeight, container.scrollTop, container.clientHeight, SCROLL_THRESHOLD_FORWARD_PERCENT, SCROLL_THRESHOLD_BACKWARD_PERCENT);
export const isThresholdReached = (scrollHeight, scrollTop, clientHeight, forwardThresholdPercent, backwardThresholdPercent) => {
    const reached = isForwardThresholdRaeched(scrollHeight, scrollTop, clientHeight, forwardThresholdPercent)
        || isBackwardThresholdReached(scrollHeight, scrollTop, backwardThresholdPercent);
    return reached;
};
const isForwardScrollThresholdReached = (container) => {
    return isForwardThresholdRaeched(container.scrollHeight, container.scrollTop, container.clientHeight);
};
const isForwardThresholdRaeched = (scrollHeight, scrollTop, clientHeight, forwardThresholdPercent = SCROLL_THRESHOLD_FORWARD_PERCENT) => {
    return (scrollTop + clientHeight) / scrollHeight > forwardThresholdPercent;
};
const isBackwardScrollThresholdReached = (container) => {
    return isBackwardThresholdReached(container.scrollHeight, container.scrollTop);
};
const isBackwardThresholdReached = (scrollHeight, scrollTop, backwardThresholdPercent = SCROLL_THRESHOLD_BACKWARD_PERCENT) => scrollTop / scrollHeight < backwardThresholdPercent;
const getScrollFetchDirection = (container) => getFetchDirection(container.scrollHeight, container.scrollTop, container.clientHeight, SCROLL_THRESHOLD_FORWARD_PERCENT, SCROLL_THRESHOLD_BACKWARD_PERCENT);
export const getFetchDirection = (scrollHeight, scrollTop, clientHeight, forwardThresholdPercent, backwardThresholdPercent) => {
    const forward = (scrollTop + clientHeight) / scrollHeight - forwardThresholdPercent;
    const backward = (scrollTop) / scrollHeight - backwardThresholdPercent;
    return Math.abs(forward) <= Math.abs(backward) ? 'forward' : 'backward';
};
