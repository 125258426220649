import { getQaTable, setAssetError } from "api-operations/editor/editor";
import AssetErrorElement from "./AssetErrorElement";
import { consumeEvent, validateJustification } from "editorNextGen/utils";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import EditorModule from "../EditorModule";
import "./style.scss";
export default class AssetErrorModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "AssetErrorModule";
        this.severityDropdownEntries = [];
        this.fetchQaTable().then(() => {
            this.severityDropdownEntries = Object.entries(this.qaTable)
                .sort(([, value1], [, value2]) => value2 - value1)
                .map(([key, value]) => ({
                value: key,
                text: i18next.t(`qaTables.${key}`),
                description: `${i18next.t('asset.severity')}: ${value}`,
                weight: value
            }));
        });
    }
    unload() {
        this.editor.content.querySelectorAll("xfl-new-editor-asset").forEach((a) => {
            this.getAssetErrorElement(a).remove();
        });
    }
    assetCreated(assetElement, asset = undefined) {
        var _a;
        const errorElement = new AssetErrorElement(this.severityDropdownEntries, (option) => this.assetErrorTypeChanged(assetElement, option), (justification) => this.assetErrorJustificationInput(assetElement, justification), (justification) => this.assetErrorJustificationChanged(assetElement, justification));
        errorElement.open = assetElement.modified;
        errorElement.error = (_a = asset === null || asset === void 0 ? void 0 : asset.error) !== null && _a !== void 0 ? _a : null;
        errorElement.disabled = assetElement.contentDiv.contentEditable !== "true";
        assetElement.actionBar.prepend(errorElement);
    }
    assetActivationChanged(asset, active) {
        if (active) {
            this.getAssetErrorElement(asset).open = asset.modified;
        }
    }
    keyDown(event) {
        if (event.key == "e" && (event.ctrlKey || event.metaKey) && this.editor.activeAsset) {
            const errorElement = this.getAssetErrorElement(this.editor.activeAsset);
            if (errorElement.open) {
                errorElement.dropdown.focusDropdown();
                consumeEvent(event);
            }
        }
    }
    input() {
        const { activeAsset } = this.editor;
        if (!activeAsset) {
            return;
        }
        const errorElement = this.getAssetErrorElement(activeAsset);
        if (activeAsset.modified) {
            activeAsset.assetError = true;
        }
        else {
            errorElement.errorType = null;
            errorElement.justification = null;
            activeAsset.assetErrorType = null;
            activeAsset.assetError = false;
            this.submitErrorData(activeAsset);
        }
        errorElement.open = activeAsset.modified;
    }
    assetInstanceLockChanged(asset, completed) {
        this.syncAssetErrorEditability(asset);
    }
    assetInstanceCompletionChanged(asset, completed) {
        this.syncAssetErrorEditability(asset);
    }
    assetCompletable(asset) {
        const assetError = this.getAssetErrorElement(asset);
        const severity = assetError.errorType ? this.qaTable[assetError.errorType] : 0;
        const justification = assetError.justification || "";
        // if severity >= 9, check if justification is valid
        const isJustificationValid = severity >= 9 ? validateJustification(justification) == "OK" : true;
        return (!asset.modified || assetError.errorType !== null) && isJustificationValid;
    }
    assetErrorTypeChanged(asset, errorType) {
        const errorElement = this.getAssetErrorElement(asset);
        errorElement.dropdown.error = errorType === null;
        asset.assetErrorType = errorType || null;
        this.submitErrorData(asset);
        this.editor.modules.forEach(m => m.assetCompletabilityChanged(asset));
    }
    assetErrorJustificationInput(asset, justification) {
        this.editor.modules.forEach(m => m.assetCompletabilityChanged(asset));
    }
    assetErrorJustificationChanged(asset, justification) {
        this.submitErrorData(asset);
    }
    async submitErrorData(asset) {
        try {
            const errorElement = this.getAssetErrorElement(asset);
            await setAssetError(asset.editor.assignmentId, asset.assetId, errorElement.errorType, errorElement.justification || null)
                .then(() => { var _a; return (_a = this.editor.scroll) === null || _a === void 0 ? void 0 : _a.updateAssetError(asset.assetId, errorElement.error); });
            HTTPLogger.info('User - Set asset error');
        }
        catch (error) {
            HTTPLogger.error('User - Failed to set asset error', error);
        }
    }
    async fetchQaTable() {
        try {
            this.qaTable = await getQaTable();
            HTTPLogger.info('System - Fetched QA table');
        }
        catch (error) {
            HTTPLogger.error('System - Failed to get QA table', error);
        }
    }
    getAssetErrorElement(asset) {
        return asset.querySelector("xfl-new-editor-asset-error");
    }
    syncAssetErrorEditability(asset) {
        const errorElement = this.getAssetErrorElement(asset);
        if (!errorElement) {
            return;
        }
        errorElement.disabled = asset.contentDiv.contentEditable !== "true";
    }
}
